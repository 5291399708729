<template>
  <documents :simulation="true" :isSimulationStepper="isSimulationStepper"></documents>
</template>

<script>
import Documents from "../../Works/Documents";

export default {
  name: "SimulationDocuments",
  components: {
    Documents
  },
  data() {
    return {
      isSimulationStepper : false
    }
  },
  created() {
    this.isSimulationStepper = this.$route.path.includes('/simulations/add') || this.$route.path.includes('/simulations/edit');
  }
};
</script>
